import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService extends TokenService {
  async init() {
    await super.init();
    await this.setTokenValue(environment.token);
  }
}
