import { createReducer, on } from '@ngrx/store';
import { BankState } from './bank.state';
import {
  BankRequestAction,
  BankFailureAction,
  BankSuccessAction,
} from './bank.actions';

export const initialBankState: BankState = {
  loading: false,
  loaded: false,
  response: undefined,
  error: undefined,
  openModal: false,
};

export const BankReducer = createReducer(
  initialBankState,
  on(
    BankRequestAction,
    (state): BankState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    BankSuccessAction,
    (state, { response }): BankState => ({
      ...state,
      loading: false,
      loaded: true,
      response: response,
      error: undefined,
    })
  ),
  on(
    BankFailureAction,
    (state, action): BankState => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  )
);
