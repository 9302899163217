import { createAction, props } from '@ngrx/store';
import {
  ResponseService,
  Token,
  StartRegisterRequest,
  RegisterRequest,
  PoliticalExposed,
  Auth,
  ParameterDetail,
} from 'src/app/core/interfaces';

// startRegister
export const startRegisterLoadingAction = createAction(
  '[User] Start Register Load'
);

export const startRegisterRequestAction = createAction(
  '[User] Start Register Request',
  props<{ params: StartRegisterRequest }>()
);

export const startRegisterSuccessAction = createAction(
  '[User] Start Register Success',
  props<{ response: ResponseService<Token>; token: string }>()
);

export const startRegisterFailureAction = createAction(
  '[User] Start Register Failure',
  props<{ error: string | string[] }>()
);

export const startRegisterCancelAction = createAction(
  '[User] Start Register Cancel'
);

// verifyCode
export const verifyCodeRequestAction = createAction(
  '[User] Verify Code Request',
  props<{ token: string; verifyCode: string }>()
);

export const verifyCodeSuccessAction = createAction(
  '[User] Verify Code Success',
  props<{ response: ResponseService<Token>; token: string }>()
);

export const verifyCodeFailureAction = createAction(
  '[User] Verify Code Failure',
  props<{ error: string | string[] }>()
);

// register
export const registerRequestAction = createAction(
  '[User] Register Request',
  props<{ request: RegisterRequest }>()
);

export const registerSuccessAction = createAction(
  '[User] Register Success',
  props<{ response: ResponseService<Auth> }>()
);

export const registerFailureAction = createAction(
  '[User] Register Failure',
  props<{ error: string | string[] }>()
);

// Cargo de Persona Politicamente expuesta
export const politicalExposedRequestAction = createAction(
  '[User] PoliticalExposed List Request'
);

export const politicalExposedSuccessAction = createAction(
  '[User] PoliticalExposed List Success',
  props<{ response: PoliticalExposed[] }>()
);

export const politicalExposedFailureAction = createAction(
  '[User] PoliticalExposed List Failure',
  props<{ error: string | string[] }>()
);

// Canales de captación
export const collectionChannelsRequestAction = createAction(
  '[User] CollectionChannels Request'
);

export const collectionChannelsSuccessAction = createAction(
  '[User] CollectionChannels Success',
  props<{ response: ParameterDetail[] }>()
);

export const collectionChannelsFailureAction = createAction(
  '[User] CollectionChannels Failure',
  props<{ error: string | string[] }>()
);
