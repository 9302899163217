import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  Auth,
  ResetPasswordRequest,
  ResponseService,
  Token,
  User,
} from '../interfaces';
import { StorageService } from './storage.service';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly http: HttpClient,
    private readonly storage: StorageService,
    private readonly navCtrl: NavController
  ) {}

  get headers() {
    let headers = new HttpHeaders();
    headers = headers
      .set('Content-Type', 'application/json')
      .set('frontend-action', 'login');
    return headers;
  }

  login(params: { email: string; password: string }) {
    const url = `${environment.apiAuth}/login`;
    return new Promise<ResponseService<Auth>>((resolve, reject) => {
      this.http
        .post<ResponseService<Auth>>(url, params, {
          headers: this.headers,
        })
        .subscribe({
          next: resp => {
            resolve(resp);
          },
          error: error => {
            reject(error);
          },
          // complete: () => console.log(`login completó la solicitud`),
        });
    });
  }

  getProfile() {
    return this.http.get<ResponseService<User>>(
      `${environment.apiAuth}/profile`
    );
  }

  singin(params: { email: string; password: string }) {
    const headers = this.headers;
    const url = `${environment.apiAuth}/login`;
    return this.http.post<ResponseService<Auth>>(url, params, { headers });
  }

  async setAuthToken(tokenValue: string) {
    await this.storage.init();
    await this.storage.set(environment.token, tokenValue);
  }

  async logout() {
    await this.storage.clear();
    this.navCtrl.navigateRoot('/login', {
      animated: true,
      animationDirection: 'back',
    });
  }

  forgotPassword(email: string) {
    const headers = this.headers;
    const url = `${environment.apiAuth}/forgot-password`;
    const params = { email, navId: environment.codSitio };
    return this.http.post<ResponseService<Token>>(url, params, { headers });
  }

  resetPassword(params: ResetPasswordRequest) {
    const headers = this.headers;
    const url = `${environment.apiAuth}/reset-password`;
    return this.http.post<ResponseService<boolean>>(url, params, { headers });
  }
}
