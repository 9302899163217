import { createReducer, on } from '@ngrx/store';
import {
  walletAmountFailureAction,
  walletAmountHideModalAction,
  walletAmountRequestAction,
  walletAmountShowModalAction,
  walletAmountSuccessAction,
  walletInfoFailureAction,
  walletInfoRequestAction,
  walletInfoSuccessAction,
} from './wallet.actions';
import { WalletAmountState, WalletInfoState } from './';

export const initialWalletAmountState: WalletAmountState = {
  loading: false,
  loaded: false,
  response: undefined,
  error: undefined,
  openModal: false,
};

export const walletAmountReducer = createReducer(
  initialWalletAmountState,
  on(
    walletAmountRequestAction,
    (state): WalletAmountState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    walletAmountSuccessAction,
    (state, { response }): WalletAmountState => ({
      ...state,
      loading: false,
      loaded: true,
      response: response,
      error: undefined,
    })
  ),
  on(
    walletAmountFailureAction,
    (state, action): WalletAmountState => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
  on(
    walletAmountShowModalAction,
    (state): WalletAmountState => ({
      ...state,
      openModal: true,
    })
  ),
  on(
    walletAmountHideModalAction,
    (state): WalletAmountState => ({
      ...state,
      openModal: false,
    })
  )
);

export const initialWalletInfoState: WalletInfoState = {
  loading: false,
  loaded: false,
  response: undefined,
  error: undefined,
};

export const walletInfoReducer = createReducer(
  initialWalletInfoState,
  on(
    walletInfoRequestAction,
    (state): WalletInfoState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    walletInfoSuccessAction,
    (state, { response }): WalletInfoState => ({
      ...state,
      loading: false,
      loaded: true,
      response: response,
      error: undefined,
    })
  ),
  on(
    walletInfoFailureAction,
    (state, action): WalletInfoState => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  )
);
