import { ActionReducerMap } from '@ngrx/store';
import { authReducer } from './auth/auth.reducers';
import { ProfileState } from './profile/profile.state';
import { AuthState } from './auth/auth.state';
import { profileReducer } from './profile/profile.reducers';
import { userReducer } from './user/user.reducers';
import { UserState } from './user/user.state';
import { UbigeoState } from './ubigeo/ubigeo.state';
import { ubigeoReducer } from './ubigeo/ubigeo.reducers';
import { ProfileEffects } from './profile/profile.effects';
import { AuthEffects } from './auth/auth.effects';
import { UserEffects } from './user/user.effects';
import { UbigeoEffects } from './ubigeo/ubigeo.effects';
import { CurrencyState, currencyReducer, CurrencyEffects } from './currency';
import { WalletAmountState, WalletInfoState } from './wallet/wallet.state';
import { walletAmountReducer } from './wallet/wallet.reducers';
import {
  WalletAmountEffects,
  WalletInfoEffects,
  walletInfoReducer,
} from './wallet';
import {
  BankAccountState,
  bankAccountReducer,
  BankAccountEffects,
} from './bank-account';
import {
  WalletTransactionState,
  walletTransactionReducer,
  WalletTransactionEffects,
} from './wallet-transactions';
import {
  ReasonsWithdrawalEffects,
  ReasonsWithdrawalReducer,
  ReasonsWithdrawalState,
} from './reasons-withdrawal';
import { BankState, BankReducer, BankEffects } from './bank';

export interface AppState {
  profile: ProfileState;
  auth: AuthState;
  user: UserState;
  ubigeo: UbigeoState;
  currency: CurrencyState;
  walletAmount: WalletAmountState;
  walletInfo: WalletInfoState;
  bankAccount: BankAccountState;
  walletTransaction: WalletTransactionState;
  reasonsWithdrawal: ReasonsWithdrawalState;
  bank: BankState;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  profile: profileReducer,
  auth: authReducer,
  user: userReducer,
  ubigeo: ubigeoReducer,
  currency: currencyReducer,
  walletAmount: walletAmountReducer,
  walletInfo: walletInfoReducer,
  bankAccount: bankAccountReducer,
  walletTransaction: walletTransactionReducer,
  reasonsWithdrawal: ReasonsWithdrawalReducer,
  bank: BankReducer,
};

export const ROOT_EFFECTS = [
  ProfileEffects,
  AuthEffects,
  UserEffects,
  UbigeoEffects,
  CurrencyEffects,
  WalletAmountEffects,
  WalletInfoEffects,
  BankAccountEffects,
  WalletTransactionEffects,
  ReasonsWithdrawalEffects,
  BankEffects,
];
