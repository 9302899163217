import { createAction, props } from '@ngrx/store';
import {
  IPaginationMeta,
  Pagination,
  WalletTransaction,
  WalletTransactionFilterDto,
} from 'src/app/core/interfaces';

export const walletTransactionRequestAction = createAction(
  '[Wallet transactions] Wallet Transaction Request',
  props<{ data: WalletTransactionFilterDto }>()
);

export const walletTransactionSuccessAction = createAction(
  '[Wallet transactions] Wallet Transaction Success',
  props<{ response: Pagination<WalletTransaction, IPaginationMeta> }>()
);

export const walletTransactionFailureAction = createAction(
  '[Wallet transactions] Wallet Transaction Failure',
  props<{ error: string | string[] }>()
);

export const walletTransactionShowModalAction = createAction(
  '[Wallet transactions] Wallet Transaction Show Modal'
);

export const walletTransactionHideModalAction = createAction(
  '[Wallet transactions] Wallet Transaction Hide Modal'
);
