import { Amounts, WalletInfo } from 'src/app/core/interfaces';

export interface WalletAmountState {
  loading: boolean;
  loaded: boolean;
  response: Readonly<Amounts>;
  error: string | string[];
  openModal: boolean;
}

export interface WalletInfoState {
  loading: boolean;
  loaded: boolean;
  response: Readonly<WalletInfo>;
  error: string | string[];
}
