import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Bank } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  #path = `${environment.apiServer}/banks`;

  constructor(private readonly http: HttpClient) {}

  banks() {
    return this.http.get<Bank[]>(`${this.#path}`);
  }

  bank(id: number) {
    return this.http.get<Bank[]>(`${this.#path}/${id}`);
  }
}
