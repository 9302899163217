import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { ReasonsWithdrawalState } from './';

export const selectReasonsWithdrawalFeature = (state: AppState) =>
  state.reasonsWithdrawal;

export const selectReasonsWithdrawalSuccess = createSelector(
  selectReasonsWithdrawalFeature,
  (ReasonsWithdrawalState: ReasonsWithdrawalState) =>
    ReasonsWithdrawalState.response
);
