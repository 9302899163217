import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { CurrencyService } from 'src/app/core/services';
import {
  currencyFailureAction,
  currencyRequestAction,
  currencySuccessAction,
} from './';

@Injectable()
export class CurrencyEffects {
  departmentRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(currencyRequestAction),
      mergeMap(() =>
        this.currencyService.currencies().pipe(
          map(response => currencySuccessAction({ response })),
          catchError(error => {
            console.log('error desde CurrencyEffects: ', error);
            return of(currencyFailureAction({ error }));
          })
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly currencyService: CurrencyService
  ) {}
}
