import { createAction, props } from '@ngrx/store';
import { Amounts, WalletInfo } from 'src/app/core/interfaces';

export const walletAmountRequestAction = createAction(
  '[Wallet] Wallet Amount Request'
);

export const walletAmountSuccessAction = createAction(
  '[Wallet] Wallet Amount Success',
  props<{ response: Amounts }>()
);

export const walletAmountFailureAction = createAction(
  '[Wallet] Wallet Amount Failure',
  props<{ error: string | string[] }>()
);

export const walletAmountShowModalAction = createAction(
  '[Wallet] Wallet Amount Show Modal'
);

export const walletAmountHideModalAction = createAction(
  '[Wallet] Wallet Amount Hide Modal'
);

export const walletInfoRequestAction = createAction(
  '[Wallet] Wallet Info Request'
);

export const walletInfoSuccessAction = createAction(
  '[Wallet] Wallet Info Success',
  props<{ response: WalletInfo }>()
);

export const walletInfoFailureAction = createAction(
  '[Wallet] Wallet Info Failure',
  props<{ error: string | string[] }>()
);
