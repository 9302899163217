import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { NavController } from '@ionic/angular';
import { ResponseService, Department } from '../interfaces';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class UbigeoService {
  #path = '/ubigeo';

  constructor(private readonly http: HttpClient) {}

  get headers() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return headers;
  }

  department() {
    const headers = this.headers;
    const url = `${environment.apiServer}${this.#path}/department`;
    // const params = { navId: environment.codSitio };
    return this.http.get<ResponseService<Department[]>>(url, { headers });
  }
}
