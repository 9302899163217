import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { UbigeoService } from 'src/app/core/services/ubigeo.service';
import {
  departmentFailureAction,
  departmentRequestAction,
  departmentSuccessAction,
} from './ubigeo.actions';

@Injectable()
export class UbigeoEffects {
  departmentRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(departmentRequestAction),
      mergeMap(() =>
        this.ubigeoService.department().pipe(
          map(response =>
            departmentSuccessAction({
              response: response?.data,
            })
          ),
          catchError(error => {
            console.log('error desde UbigeoEffects: ', error);
            return of(departmentFailureAction({ error }));
          })
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly ubigeoService: UbigeoService
  ) {}
}
