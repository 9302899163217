import { createAction, props } from '@ngrx/store';
import { Bank } from 'src/app/core/interfaces';

export const BankRequestAction = createAction('[Bank] Bank Request');

export const BankSuccessAction = createAction(
  '[Bank] Bank Success',
  props<{ response: Bank[] }>()
);

export const BankFailureAction = createAction(
  '[Bank] Bank Failure',
  props<{ error: string | string[] }>()
);
