export enum ProfileStatusEnum {
  PARA_ANALIZAR = 0,
  PRE_VALIDADO_EP = 1,
  RECHAZADO_EP = 2,
  VALIDADO = 3,
  RECHAZADO = 4,
  FRAUDULENTO = 5,
  NO_FINALIZADO = 6,
}

/**
 * 	CASE

			WHEN ( `fp`.`kyc_status` = 0 ) THEN	'PARA_ANALIZAR'
			WHEN ( `fp`.`kyc_status` = 1 ) THEN	'PRE_VALIDADO_EP'
			WHEN ( `fp`.`kyc_status` = 2 ) THEN	'RECHAZADO_EP'
			WHEN ( `fp`.`kyc_status` = 3 ) THEN	'VALIDADO'
			WHEN ( `fp`.`kyc_status` = 4 ) THEN	'RECHAZADO'
			WHEN ( `fp`.`kyc_status` = 5 ) THEN	'FRAUDULENTO'
			WHEN ( `fp`.`kyc_status` = 6 ) THEN	'NO_FINALIZADO'
		END
 */
