import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  #helper = new JwtHelperService();
  #tokenValue: string | null = null;

  constructor(private readonly storageService: StorageService) {}

  async init() {
    await this.storageService.init();
  }

  async setTokenValue(tokenName: string) {
    this.#tokenValue = await this.storageService.get(tokenName);
  }

  public getTokenValue() {
    return this.#tokenValue;
  }

  public isTokenExpired() {
    return this.#helper.isTokenExpired(this.#tokenValue);
  }

  public getTokenExpirationDate() {
    return this.#helper.getTokenExpirationDate(this.#tokenValue);
  }

  public getTokenData() {
    return this.#helper.decodeToken(this.#tokenValue);
  }

  public async clearToken() {
    await this.storageService.remove(this.#tokenValue);
  }

  public async clearTokens() {
    await this.storageService.clear();
  }
}
