import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TokenService } from 'src/app/core/services/token.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import { loginSuccessAction } from '../auth/auth.actions';
import {
  collectionChannelsFailureAction,
  collectionChannelsRequestAction,
  collectionChannelsSuccessAction,
  politicalExposedFailureAction,
  politicalExposedRequestAction,
  politicalExposedSuccessAction,
  registerFailureAction,
  registerRequestAction,
  registerSuccessAction,
  startRegisterCancelAction,
  startRegisterFailureAction,
  startRegisterRequestAction,
  startRegisterSuccessAction,
  verifyCodeRequestAction,
  verifyCodeSuccessAction,
} from './user.actions';

@Injectable()
export class UserEffects {
  startRegisterRequest$ = createEffect(() => {
    const action = this.actions$;
    return action.pipe(
      ofType(startRegisterRequestAction),
      mergeMap(({ params }) =>
        this.userService.startRegister(params).pipe(
          map(response =>
            startRegisterSuccessAction({
              response,
              token: response.data.token,
            })
          ),
          catchError(error => {
            console.log('error desde UserEffects: ', error);
            return of(startRegisterFailureAction({ error }));
          })
        )
      )
    );
  });

  startRegisterSuccess$ = createEffect(
    () => {
      const action = this.actions$;
      return action.pipe(
        ofType(startRegisterSuccessAction),
        tap(async ({ response }) => {
          const tokenName = environment.tokenStartRegister;
          const tokenValue = response.data.token;
          await this.storage.init();
          await this.storage.set(tokenName, tokenValue);
          this.navCtrl.navigateRoot(['register', 'verify'], {
            animated: true,
            animationDirection: 'forward',
          });
        })
      );
    },
    { dispatch: false }
  );

  startRegisterCancel$ = createEffect(
    () => {
      const action = this.actions$;
      return action.pipe(
        ofType(startRegisterCancelAction),
        tap(async () => {
          console.log('startRegisterCancel$');
          await this.storage.clear();
          this.navCtrl.navigateRoot('/register', {
            animated: true,
            animationDirection: 'back',
          });
        })
      );
    },
    { dispatch: false }
  );

  verifyCodeRequest$ = createEffect(() => {
    const action = this.actions$;
    return action.pipe(
      ofType(verifyCodeRequestAction),
      mergeMap(({ verifyCode, token }) =>
        this.userService.verifyCode(verifyCode, token).pipe(
          map(response =>
            verifyCodeSuccessAction({
              response,
              token: response.data.token,
            })
          ),
          catchError(error => {
            console.log('error desde verifyCodeRequest: ', error);
            return of(startRegisterFailureAction({ error }));
          })
        )
      )
    );
  });

  verifyCodeSuccess$ = createEffect(
    () => {
      const action = this.actions$;
      return action.pipe(
        ofType(verifyCodeSuccessAction),
        tap(async ({ response }) => {
          const tokenName = environment.tokenUserValidated;
          const tokenValue = response.data.token;
          await this.storage.init();
          await this.storage.set(tokenName, tokenValue);
          await this.storage.remove(environment.tokenStartRegister);
          await this.tokenService.init();
          await this.tokenService.setTokenValue(tokenName);
          const tokenData = this.tokenService.getTokenData();
          let url = '/';
          switch (tokenData.data.accountType as number) {
            case 1:
              url = '/register-investor';
              break;
            case 2:
              url = '/register-investor';
              console.log('Muy pronto...');
              break;
          }
          this.navCtrl.navigateRoot(url, {
            animated: true,
            animationDirection: 'forward',
          });
        })
      );
    },
    { dispatch: false }
  );

  registerRequest$ = createEffect(() => {
    const action = this.actions$;
    return action.pipe(
      ofType(registerRequestAction),
      mergeMap(({ request }) =>
        this.userService.register(request).pipe(
          map(response => registerSuccessAction({ response })),
          catchError(error => {
            console.log('error desde registerRequest: ', error);
            return of(registerFailureAction({ error }));
          })
        )
      )
    );
  });

  registerSuccess$ = createEffect(
    () => {
      const action = this.actions$;
      return action.pipe(
        ofType(registerSuccessAction),
        tap(async ({ response }) => {
          // await this.storage.clear();
          console.log('registerSuccess$Effects', response);
          await this.storage.clear();
          // await this.navCtrl.navigateRoot('/login', {
          //   animated: true,
          //   animationDirection: 'forward',
          // });
          this.navCtrl.navigateRoot('/dashboard', {
            animated: true,
            animationDirection: 'forward',
          });
          await this.authService.setAuthToken(response.data.accessToken);
          const html = `<div class="ion-text-center">
  <ion-icon name="checkmark-circle-sharp" class="success"></ion-icon>
  <div class="body-info">
    <h2>¡Felicitaciones!</h2>
    <p>Ya estás listo para empezar a rentabilizar tu dinero.</p>
  </div>
</div>`;
          this.generalService.showAlert(
            html,
            null,
            null,
            `Listo`,
            'alert-custom'
          );
        })
      );
    },
    { dispatch: false }
  );

  politicalExposedRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(politicalExposedRequestAction),
      mergeMap(() =>
        this.userService.politicalExposedList().pipe(
          map(response =>
            politicalExposedSuccessAction({
              response: response?.data,
            })
          ),
          catchError(error => {
            console.log('error desde politicalExposedRequest: ', error);
            return of(politicalExposedFailureAction({ error }));
          })
        )
      )
    );
  });

  // Canales de captación
  collectionChannelsRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(collectionChannelsRequestAction),
      mergeMap(() =>
        this.userService.getCollectionChannels().pipe(
          map(response =>
            collectionChannelsSuccessAction({
              response,
            })
          ),
          catchError(error => {
            console.log('error desde collectionChannelsRequest: ', error);
            return of(collectionChannelsFailureAction({ error }));
          })
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly storage: StorageService,
    private readonly userService: UserService,
    private readonly navCtrl: NavController,
    private readonly tokenService: TokenService,
    private readonly generalService: GeneralService,
    private readonly authService: AuthService
  ) {}
}
