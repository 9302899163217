import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  #storage: Storage | null = null;

  constructor(private readonly storage: Storage) {}

  async init() {
    if (!this.#storage) {
      // console.log('StorageService se ha creado...');
      this.#storage = await this.storage.create();
    }
  }

  public async set(key: string, value: any) {
    await this.#storage?.set(key, value);
  }

  public async get(key: string) {
    return await this.#storage?.get(key);
  }

  public async remove(key: string) {
    await this.#storage?.remove(key);
  }

  public async clear() {
    await this.#storage?.clear();
  }

  public async keys() {
    return await this.#storage?.keys();
  }
}
