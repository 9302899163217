import { Injectable } from '@angular/core';
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  constructor(private platform: Platform) {}

  public async addNewToGallery() {
    try {
      // Take a photo
      const capturedPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        allowEditing: false,
        quality: 90,
        width: 1000,
        height: 653,
      });
      return capturedPhoto;
    } catch (error) {
      // console.log(error);
    }
  }

  public async savePhoto(photo: Photo) {}
}
