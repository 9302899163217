import { createAction, props } from '@ngrx/store';
import { BankAccount } from 'src/app/core/interfaces';

export const bankAccountPenRequestAction = createAction(
  '[BankAccount] BankAccount PEN Request'
);

export const bankAccountPenSuccessAction = createAction(
  '[BankAccount] BankAccount PEN Success',
  props<{ response: BankAccount[] }>()
);

export const bankAccountPenFailureAction = createAction(
  '[BankAccount] BankAccount PEN Failure',
  props<{ error: string | string[] }>()
);

export const bankAccountUsdRequestAction = createAction(
  '[BankAccount] BankAccount USD Request'
);

export const bankAccountUsdSuccessAction = createAction(
  '[BankAccount] BankAccount USD Success',
  props<{ response: BankAccount[] }>()
);

export const bankAccountUsdFailureAction = createAction(
  '[BankAccount] BankAccount USD Failure',
  props<{ error: string | string[] }>()
);
