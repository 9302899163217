import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { from, Observable, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthTokenService } from '../services/auth-token.service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
  constructor(private readonly authTokenService: AuthTokenService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const resp = from(this.authTokenService.init()).pipe(
      switchMap(() => {
        const token = this.authTokenService.getTokenValue();
        if (token) {
          request = request.clone({
            headers: request.headers.set(
              'Authorization',
              `${environment.tokenWord} ${token}`
            ),
          });
        }
        return next.handle(request);
      })
    );
    return resp;
  }
}
