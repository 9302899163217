import { createAction, props } from '@ngrx/store';
import { Department } from 'src/app/core/interfaces';

export const departmentRequestAction = createAction(
  '[Ubigeo] Department Request'
);

export const departmentSuccessAction = createAction(
  '[Ubigeo] Department Success',
  props<{ response: Department[] }>()
);

export const departmentFailureAction = createAction(
  '[Ubigeo] Department Failure',
  props<{ error: string | string[] }>()
);
