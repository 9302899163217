import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  StartRegisterRequest,
  ResponseService,
  Token,
  RegisterRequest,
  User,
  PoliticalExposed,
  Auth,
  RegisterGetDniRequest,
  RegisterDniResponse,
  ParameterDetail,
} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  #path = '/user';

  constructor(private readonly http: HttpClient) {}

  get headers() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    // .set('frontend-action', 'login');
    return headers;
  }

  startRegister(input: StartRegisterRequest) {
    const headers = this.headers;
    const url = `${environment.apiServer}${this.#path}/start-register`;
    const params = { ...input, navId: environment.codSitio };
    return this.http.post<ResponseService<Token>>(url, params, { headers });
  }

  verifyCode(verifyCode: string, token: string) {
    const headers = this.headers;
    const url = `${environment.apiServer}${this.#path}/verify-code`;
    const params = { verifyCode, token };
    return this.http.post<ResponseService<Token>>(url, params, { headers });
  }

  register(input: RegisterRequest) {
    const headers = this.headers;
    const url = `${environment.apiServer}${this.#path}/register`;
    const params = { ...input };
    return this.http.post<ResponseService<Auth>>(url, params, { headers });
  }

  registerGetDni(input: RegisterGetDniRequest) {
    const headers = this.headers;
    const url = `${environment.apiServer}${this.#path}/register-get-dni`;
    const params = { ...input };
    return this.http.post<RegisterDniResponse>(url, params, { headers });
  }

  autoSaveProfile<T>(params: T) {
    let headers = this.headers;
    const { silence }: any = params;
    if (silence === true) {
      headers = headers.set('frontend-action', 'silence');
    }
    const url = `${environment.apiServer}${this.#path}/profile`;
    // const params = { field: value };
    return this.http.put<ResponseService<User>>(url, params, { headers });
  }

  autoSaveCompany<T>(params: T) {
    let headers = this.headers;
    headers = headers.set('frontend-action', 'silence');
    const url = `${environment.apiServer}${this.#path}/company`;
    return this.http.put<ResponseService<User>>(url, params, { headers });
  }

  politicalExposedList() {
    const headers = this.headers;
    const url = `${environment.apiServer}${this.#path}/political-exposed`;
    return this.http.get<ResponseService<PoliticalExposed[]>>(url, { headers });
  }

  getCollectionChannels() {
    const headers = this.headers;
    const url = `${environment.apiServer}${this.#path}/collection-channels`;
    return this.http.get<ParameterDetail[]>(url, { headers });
  }

  uploadFile(body: FormData, service: string) {
    const services = ['dni-frontal', 'dni-reverse', 'literal-copy', 'file-ruc'];
    const endpoint = services.find(serv => serv === service);
    if (!endpoint) {
      throw new Error('Servicio no dispoble.');
    }
    const url = `${environment.apiServer}/upload/${endpoint}`;
    return this.http.post<ResponseService<User>>(url, body);
  }

  getDownloadUrl() {
    return `${environment.apiServer}/download`;
  }
}
