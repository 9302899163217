import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { WalletAmountState, WalletInfoState } from './';

export const selectWalletAmountFeature = (state: AppState) =>
  state.walletAmount;

export const selectWalletAmountSuccess = createSelector(
  selectWalletAmountFeature,
  (walletAmountState: WalletAmountState) => walletAmountState.response
);

export const selectWalletAmountFailure = createSelector(
  selectWalletAmountFeature,
  (walletAmountState: WalletAmountState) => walletAmountState.error
);

export const selectWalletStateModal = createSelector(
  selectWalletAmountFeature,
  (walletAmountState: WalletAmountState) => walletAmountState.openModal
);

export const selectWalletInfoFeature = (state: AppState) => state.walletInfo;

export const selectWalletInfoSuccess = createSelector(
  selectWalletInfoFeature,
  (walletInfoState: WalletInfoState) => walletInfoState.response
);

export const selectWalletInfoFailure = createSelector(
  selectWalletInfoFeature,
  (walletInfoState: WalletInfoState) => walletInfoState.error
);
