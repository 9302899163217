import {
  IPaginationMeta,
  Pagination,
  WalletTransaction,
} from 'src/app/core/interfaces';

export interface WalletTransactionState {
  loading: boolean;
  loaded: boolean;
  response: Readonly<Pagination<WalletTransaction, IPaginationMeta>>;
  error: string | string[];
  openModal: boolean;
}
