import { createReducer, on } from '@ngrx/store';
import {
  currencyFailureAction,
  currencyRequestAction,
  currencySuccessAction,
} from './currency.actions';
import { CurrencyState } from './';

export const initialCurrencyState: CurrencyState = {
  loading: false,
  loaded: false,
  response: undefined,
  error: undefined,
};

export const currencyReducer = createReducer(
  initialCurrencyState,
  on(
    currencyRequestAction,
    (state): CurrencyState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    currencySuccessAction,
    (state, { response }): CurrencyState => ({
      ...state,
      loading: false,
      loaded: true,
      response: response,
      error: undefined,
    })
  ),
  on(
    currencyFailureAction,
    (state, action): CurrencyState => ({
      ...state,
      loading: false,
      loaded: false,
      // response: undefined,
      error: action.error,
    })
  )
);
