export enum ShareOptionEnum {
  WHATSAPP = 'whatsapp',
  TELEGRAM = 'telegram',
  SKYPE = 'skype',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  MAIL = 'mail',
  CLIPBOARD = 'clipboard',
}
