import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { CurrencyState } from './';

export const selectCurrencyFeature = (state: AppState) => state.currency;

export const selectCurrencySuccess = createSelector(
  selectCurrencyFeature,
  (currencyState: CurrencyState) => currencyState.response
);

export const selectCurrencyFailure = createSelector(
  selectCurrencyFeature,
  (currencyState: CurrencyState) => currencyState.error
);
