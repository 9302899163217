import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { from, Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralService } from '../services/general.service';
import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly generalService: GeneralService,
    private readonly tokenService: TokenService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const resp = from(this.tokenService.init()).pipe(
      switchMap(async () => {
        await this.tokenService.setTokenValue(environment.tokenResetPassword);
        const token = this.tokenService.getTokenData();
        if (token) {
          const isTokenExpired = this.tokenService.isTokenExpired();
          if (isTokenExpired) {
            this.showAlert();
            await this.tokenService.clearToken();
            await this.goToForgotPasswordPage();
            return false;
          }
          return true;
        }
        await this.tokenService.clearToken();
        await this.goToForgotPasswordPage();
        return false;
      })
    );
    return resp;
  }

  showAlert() {
    this.generalService.showAlert(
      `Su sesión ha expirado. Por favor, vuelva a ingresar su correo.`,
      null,
      `Aviso`,
      `Aceptar`
    );
  }

  async goToForgotPasswordPage() {
    await this.router.navigateByUrl('/forgot-password');
  }
}
