import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { BankAccountState } from './';

export const bankAccountStateFeature = (state: AppState) => state.bankAccount;

// PEN
export const selectBankAccountPenRequest = createSelector(
  bankAccountStateFeature,
  (bankAccountState: BankAccountState) => bankAccountState.loadedPen
);

export const selectBankAccountPenSuccess = createSelector(
  bankAccountStateFeature,
  (bankAccountState: BankAccountState) => bankAccountState.responsePen
);

export const selectBankAccountPenFailure = createSelector(
  bankAccountStateFeature,
  (bankAccountState: BankAccountState) => bankAccountState.errorPen
);

// USD
export const selectBankAccountUsdRequest = createSelector(
  bankAccountStateFeature,
  (bankAccountState: BankAccountState) => bankAccountState.loadedUsd
);

export const selectBankAccountUsdSuccess = createSelector(
  bankAccountStateFeature,
  (bankAccountState: BankAccountState) => bankAccountState.responseUsd
);

export const selectBankAccountUsdFailure = createSelector(
  bankAccountStateFeature,
  (bankAccountState: BankAccountState) => bankAccountState.errorUsd
);
