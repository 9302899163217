import { createReducer, on } from '@ngrx/store';
import * as BankAccountActions from './bank-account.actions';
import { BankAccountState } from './';

export const initialBankAccountState: BankAccountState = {
  loadingPen: false,
  loadedPen: false,
  responsePen: undefined,
  errorPen: undefined,

  loadingUsd: false,
  loadedUsd: false,
  responseUsd: undefined,
  errorUsd: undefined,
};

export const bankAccountReducer = createReducer(
  initialBankAccountState,
  // PEN
  on(
    BankAccountActions.bankAccountPenRequestAction,
    (state): BankAccountState => ({
      ...state,
      loadingPen: true,
      errorPen: undefined,
    })
  ),
  on(
    BankAccountActions.bankAccountPenSuccessAction,
    (state, { response }): BankAccountState => ({
      ...state,
      loadingPen: false,
      responsePen: response,
      errorPen: undefined,
    })
  ),
  on(
    BankAccountActions.bankAccountPenFailureAction,
    (state, action): BankAccountState => ({
      ...state,
      loadingPen: false,
      errorPen: action.error,
    })
  ),
  // USD
  on(
    BankAccountActions.bankAccountUsdRequestAction,
    (state): BankAccountState => ({
      ...state,
      loadingUsd: true,
      errorUsd: undefined,
    })
  ),
  on(
    BankAccountActions.bankAccountUsdSuccessAction,
    (state, { response }): BankAccountState => ({
      ...state,
      loadingUsd: false,
      responseUsd: response,
      errorUsd: undefined,
    })
  ),
  on(
    BankAccountActions.bankAccountUsdFailureAction,
    (state, action): BankAccountState => ({
      ...state,
      loadingUsd: false,
      errorUsd: action.error,
    })
  )
);
