import { createReducer, on } from '@ngrx/store';
import { ReasonWithdrawal } from 'src/app/core/interfaces';
import { ReasonsWithdrawalState } from './reasons-withdrawal.state';
import {
  reasonsWithdrawalFailureAction,
  reasonsWithdrawalRequestAction,
  reasonsWithdrawalSuccessAction,
} from './reasons-withdrawal.actions';

export const initialReasonsWithdrawalState: ReasonsWithdrawalState = {
  loading: false,
  loaded: false,
  response: undefined,
  error: undefined,
  openModal: false,
};

export const ReasonsWithdrawalReducer = createReducer(
  initialReasonsWithdrawalState,
  on(
    reasonsWithdrawalRequestAction,
    (state): ReasonsWithdrawalState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    reasonsWithdrawalSuccessAction,
    (state, { response }): ReasonsWithdrawalState => ({
      ...state,
      loading: false,
      loaded: true,
      response: response,
      error: undefined,
    })
  ),
  on(
    reasonsWithdrawalFailureAction,
    (state, action): ReasonsWithdrawalState => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  )
);
