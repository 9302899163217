import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DocumentTemplate } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class DocumentTemplateService {
  #path = `${environment.apiServer}/pdfs`;

  constructor(private readonly http: HttpClient) {}

  getSwornDeclaration() {
    return this.http.get<DocumentTemplate>(`${this.#path}/sworn-declaration`);
  }

  getSwornDeclarationPreview(fileName: string, fileBlob: Blob) {
    const body = new FormData();
    body.append('file', fileBlob, fileName);
    return this.http.post<any>(
      `${this.#path}/sworn-declaration-preview`,
      body,
      {
        responseType: 'blob' as 'json',
      }
    );
  }
}
