import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreateBankAccount } from '../dtos';
import { BankAccount, IbanType } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class BankAccountService {
  #path = `${environment.apiServer}/bank-account`;

  constructor(private readonly http: HttpClient) {}

  get headers() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return headers;
  }

  accounts(point: 'pen' | 'usd') {
    return this.http.post<BankAccount[]>(`${this.#path}/${point}`, {});
  }

  getAccountsOfPen() {
    return this.accounts('pen');
  }

  getAccountsOfUsd() {
    return this.accounts('usd');
  }

  getManyIbanType(): IbanType[] {
    return [
      {
        label: 'Ahorros',
        value: 'A',
      },
      {
        label: 'Corriente',
        value: 'C',
      },
    ];
  }

  getIbanTypeName(ibanType: string) {
    const result = this.getManyIbanType().find(item => {
      return item.value === ibanType;
    });
    return result?.label ?? '';
  }

  getIbanStatus(ibanStatus: number) {
    switch (ibanStatus) {
      case 2:
        return {
          color: 'success',
          name: 'Validado',
        };
      case 3:
        return {
          color: 'danger',
          name: 'Pendiente',
        };
      case 4:
        return {
          color: 'warning',
          name: 'No válido',
        };
      default:
        return {
          color: 'medium',
          name: 'No establecido',
        };
    }
  }

  addAccount(dto: CreateBankAccount, point: 'pen' | 'usd') {
    return this.http.post<BankAccount>(`${this.#path}/${point}/add`, dto);
  }

  updateOneAtDefault(id: number) {
    return this.http.post<BankAccount>(`${this.#path}/at-default/${id}`, {});
  }

  deleteAccount(id: number) {
    return this.http.delete<boolean>(`${this.#path}/${id}`);
  }
}
