import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { BankState } from './';

export const selectBankFeature = (state: AppState) => state.bank;

export const selectBankSuccess = createSelector(
  selectBankFeature,
  (BankState: BankState) => BankState.response
);
