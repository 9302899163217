import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap, concatMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TokenService } from 'src/app/core/services/token.service';
import { environment } from 'src/environments/environment';
import {
  forgotPasswordCancelAction,
  forgotPasswordFailureAction,
  forgotPasswordRequestAction,
  forgotPasswordSuccessAction,
  loginFailureAction,
  loginRequestAction,
  loginSuccessAction,
  logoutAction,
  resetPasswordFailureAction,
  resetPasswordRequestAction,
  resetPasswordSuccessAction,
} from './auth.actions';

@Injectable()
export class AuthEffects {
  loginRequest$ = createEffect(() => {
    const action = this.actions$;
    return action.pipe(
      ofType(loginRequestAction),
      mergeMap(credentials =>
        this.authService.singin(credentials).pipe(
          map(loginSuccessResponse =>
            loginSuccessAction({ loginSuccessResponse })
          ),
          catchError(error => {
            console.log('error desde AuthEffects: ', error);
            return of(loginFailureAction({ error }));
          })
        )
      )
    );
  });

  loginSuccess$ = createEffect(
    () => {
      const action = this.actions$;
      return action.pipe(
        ofType(loginSuccessAction),
        tap(async ({ loginSuccessResponse }) => {
          await this.authService.setAuthToken(
            loginSuccessResponse.data.accessToken
          );
          // await this.authTokenService.init();
          // const expirationDate = this.authTokenService.getTokenExpirationDate();
          // const timeInterval = expirationDate.getTime() - new Date().getTime();
          // console.log('expirationDate: ', expirationDate);
          // setTimeout(() => {
          //   console.log('La sesión ha terminado....');
          // }, timeInterval);
          this.navCtrl.navigateRoot('/dashboard', {
            animated: true,
            animationDirection: 'forward',
          });
        })
      );
    },
    { dispatch: false } // TODO: Si no se aplica esto será ejecutado muchas veces
  );

  forgotPasswordRequest$ = createEffect(() => {
    const action = this.actions$;
    return action.pipe(
      ofType(forgotPasswordRequestAction),
      mergeMap(({ email }) =>
        this.authService.forgotPassword(email).pipe(
          map(response => forgotPasswordSuccessAction({ response })),
          catchError(error => {
            console.log('error desde forgotPasswordRequest: ', error);
            return of(forgotPasswordFailureAction({ error }));
          })
        )
      )
    );
  });

  forgotPasswordSuccess$ = createEffect(
    () => {
      const action = this.actions$;
      return action.pipe(
        ofType(forgotPasswordSuccessAction),
        tap(async ({ response }) => {
          const tokenName = environment.tokenResetPassword;
          const tokenValue = response.data.token;
          await this.storage.init();
          await this.storage.remove(tokenName);
          await this.storage.set(tokenName, tokenValue);
          const url = '/reset-password';
          this.navCtrl.navigateRoot(url, {
            animated: true,
            animationDirection: 'forward',
          });
        })
      );
    },
    { dispatch: false }
  );

  forgotPasswordCancel$ = createEffect(
    () => {
      const action = this.actions$;
      return action.pipe(
        ofType(forgotPasswordCancelAction),
        tap(async () => {
          // console.log('forgotPasswordCancel$');
          await this.storage.remove(environment.tokenResetPassword);
          this.navCtrl.navigateRoot('/forgot-password', {
            animated: true,
            animationDirection: 'back',
          });
        })
      );
    },
    { dispatch: false }
  );

  resetPasswordRequest$ = createEffect(() => {
    const action = this.actions$;
    return action.pipe(
      ofType(resetPasswordRequestAction),
      mergeMap(({ params }) =>
        this.authService.resetPassword(params).pipe(
          map(response => resetPasswordSuccessAction({ response })),
          catchError(error => {
            console.log('error desde resetPasswordRequest: ', error);
            return of(resetPasswordFailureAction({ error }));
          })
        )
      )
    );
  });

  logout$ = createEffect(
    () => {
      const action = this.actions$;
      return action.pipe(
        ofType(logoutAction),
        tap(async () => await this.authService.logout())
      );
    },
    { dispatch: false }
  );

  // resetPasswordCancel$ = createEffect(
  //   () => {
  //     const action = this.actions$;
  //     return action.pipe(
  //       ofType(resetPasswordCancelAction),
  //       tap(async () => {
  //         await this.storage.remove(environment.tokenResetPassword);
  //         this.navCtrl.navigateRoot('/forgot-password', {
  //           animated: true,
  //           animationDirection: 'back',
  //         });
  //       }),
  //     );
  //   },
  //   { dispatch: false },
  // );

  constructor(
    private readonly actions$: Actions,
    private readonly storage: StorageService,
    private readonly authService: AuthService,
    private readonly navCtrl: NavController
  ) {}
}
