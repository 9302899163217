import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(
    private readonly alertCtrl: AlertController,
    private readonly loadingCtrl: LoadingController
  ) {}

  async showAlert(
    message: string,
    subHeader?: string,
    header?: string,
    btnText: string = 'Cerrar',
    cssClass?: string
  ) {
    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons: [btnText],
      cssClass,
    });

    await alert.present();
  }

  async showLoading(message: string = 'Procesando...') {
    const loading = await this.loadingCtrl.create({
      message,
      spinner: 'bubbles',
    });
    await loading.present();
    return loading;
  }

  /* async presentAlertConfirm() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm!',
      message: 'Message <strong>text</strong>!!!',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Okay',
          handler: () => {
            console.log('Confirm Okay');
          },
        },
      ],
    });

    await alert.present();
  } */
}
