import { createAction, props } from '@ngrx/store';
import { ResponseService, User } from 'src/app/core/interfaces';

export const loadProfile = createAction('[Profile] Load Request');

export const loadedProfile = createAction(
  '[Profile] Load success',
  props<{ response: Readonly<ResponseService<User>> }>()
);

export const autoSaveProfileRequestAction = createAction(
  '[Profile] Auto Save Profile Request',
  props<{ request: any }>()
);

export const autoSaveProfileSuccessAction = createAction(
  '[Profile] Auto Save Profile Success',
  props<{ response: Readonly<ResponseService<User>> }>()
);

export const autoSaveProfileFailureAction = createAction(
  '[Profile] Auto Save Profile Failure',
  props<{ error: string | string[] }>()
);

export const autoSaveCompanyRequestAction = createAction(
  '[Company] Auto Save Company Request',
  props<{ request: any }>()
);

export const autoSaveCompanySuccessAction = createAction(
  '[Company] Auto Save Company Success',
  props<{ response: Readonly<ResponseService<User>> }>()
);

export const autoSaveCompanyFailureAction = createAction(
  '[Company] Auto Save Company Failure',
  props<{ error: string | string[] }>()
);

export const uploadProfileRequestAction = createAction(
  '[Profile] Upload Profile Request',
  props<{ file: FormData; service: string }>()
);

export const uploadProfileSuccessAction = createAction(
  '[Profile] Upload Profile Success',
  props<{ response: Readonly<ResponseService<User>> }>()
);

export const uploadProfileFailureAction = createAction(
  '[Profile] Upload Profile Failure',
  props<{ error: string | string[] }>()
);
