import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { map, mergeMap, catchError, tap, concatMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import {
  autoSaveCompanyFailureAction,
  autoSaveCompanyRequestAction,
  autoSaveCompanySuccessAction,
  autoSaveProfileFailureAction,
  autoSaveProfileRequestAction,
  autoSaveProfileSuccessAction,
  loadedProfile,
  loadProfile,
  uploadProfileFailureAction,
  uploadProfileRequestAction,
  uploadProfileSuccessAction,
} from './profile.actions';

@Injectable()
export class ProfileEffects {
  // loadProfile$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(loadProfile),
  //     tap(() => {
  //       console.log('iniciando');
  //     }),
  //     mergeMap((action) => {
  //       console.log('ddd');
  //       return this.authService
  //         .getProfile()
  //         .pipe(map((res) => loadedProfile({ profile: res })));
  //     }),
  //   );
  // });

  profileEffectsOK = createEffect(() => {
    const action = this.actions$;
    return action.pipe(
      ofType(loadProfile),
      mergeMap(() =>
        this.authService.getProfile().pipe(
          map(response => loadedProfile({ response })),
          catchError(() => EMPTY)
        )
      )
    );
  });
  /* effectOK = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadProfile),
      concatMap(() => ...),
    )
  }) */

  autoSaveProfileRequest$ = createEffect(() => {
    const action = this.actions$;
    return action.pipe(
      ofType(autoSaveProfileRequestAction),
      mergeMap(({ request }) =>
        this.userService.autoSaveProfile(request).pipe(
          map(response =>
            autoSaveProfileSuccessAction({
              response,
            })
          ),
          catchError(error => {
            return of(autoSaveProfileFailureAction({ error }));
          })
        )
      )
    );
  });

  autoSaveCompanyRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(autoSaveCompanyRequestAction),
      mergeMap(({ request }) =>
        this.userService.autoSaveCompany(request).pipe(
          map(response =>
            autoSaveCompanySuccessAction({
              response,
            })
          ),
          catchError(error => {
            return of(autoSaveCompanyFailureAction({ error }));
          })
        )
      )
    );
  });

  uploadProfileRequest$ = createEffect(() => {
    const action = this.actions$;
    return action.pipe(
      ofType(uploadProfileRequestAction),
      mergeMap(({ file, service }) =>
        this.userService.uploadFile(file, service).pipe(
          map(response =>
            uploadProfileSuccessAction({
              response,
            })
          ),
          catchError(error => {
            return of(uploadProfileFailureAction({ error }));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private readonly authService: AuthService,
    private readonly userService: UserService
  ) {}
}
