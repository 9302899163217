import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { WalletService } from 'src/app/core/services';
import {
  walletAmountRequestAction,
  walletAmountSuccessAction,
  walletAmountFailureAction,
  walletInfoRequestAction,
  walletInfoSuccessAction,
  walletInfoFailureAction,
} from './';

@Injectable()
export class WalletAmountEffects {
  walletAmountRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(walletAmountRequestAction),
      mergeMap(() =>
        this.walletService.amounts().pipe(
          map(response => walletAmountSuccessAction({ response })),
          catchError(error => {
            console.log('error desde WalletAmountEffects: ', error);
            return of(walletAmountFailureAction({ error }));
          })
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly walletService: WalletService
  ) {}
}

@Injectable()
export class WalletInfoEffects {
  walletInfoRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(walletInfoRequestAction),
      mergeMap(() =>
        this.walletService.getInfo().pipe(
          map(response => walletInfoSuccessAction({ response })),
          catchError(error => {
            console.log('error desde WalletInfoEffects: ', error);
            return of(walletInfoFailureAction({ error }));
          })
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly walletService: WalletService
  ) {}
}
