import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { WalletService } from 'src/app/core/services';
import { IPaginationMeta, Pagination } from '../../core/interfaces/pagination';
import {
  walletTransactionRequestAction,
  walletTransactionSuccessAction,
  walletTransactionFailureAction,
} from './';
import { WalletTransaction } from 'src/app/core/interfaces';

@Injectable()
export class WalletTransactionEffects {
  walletTransactionRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(walletTransactionRequestAction),
      mergeMap(({ data }) =>
        this.walletService.transactions(data).pipe(
          map((response: Pagination<WalletTransaction, IPaginationMeta>) =>
            walletTransactionSuccessAction({ response })
          ),
          catchError(error => {
            console.log('error desde WalletTransactionEffects: ', error);
            return of(walletTransactionFailureAction({ error }));
          })
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly walletService: WalletService
  ) {}
}
