import { createReducer, on } from '@ngrx/store';
import { ProfileState } from './profile.state';
import {
  autoSaveCompanySuccessAction,
  autoSaveProfileSuccessAction,
  loadedProfile,
  loadProfile,
  uploadProfileSuccessAction,
} from './profile.actions';

export const initialState: ProfileState = {
  loading: false,
  response: undefined,
  // user: undefined,
  error: undefined,
  autoSaveProfileError: undefined,
  autoSaveCompanyError: undefined,
  isLegal: false,
};

export const profileReducer = createReducer(
  initialState,
  on(
    loadProfile,
    (state): ProfileState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    loadedProfile,
    (state, { response }): ProfileState => ({
      ...state,
      loading: false,
      response,
      // user: response?.data,
    })
  ),
  on(
    autoSaveProfileSuccessAction,
    (state, { response }): ProfileState => ({
      ...state,
      loading: false,
      response,
      // user: response?.data,
    })
  ),
  on(
    autoSaveCompanySuccessAction,
    (state, { response }): ProfileState => ({
      ...state,
      loading: false,
      response,
      // user: response?.data,
    })
  ),
  on(
    uploadProfileSuccessAction,
    (state, { response }): ProfileState => ({
      ...state,
      loading: false,
      response,
    })
  )
);
