import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { ReasonWithdrawal } from 'src/app/core/interfaces';
import { WalletService } from 'src/app/core/services';
import {
  reasonsWithdrawalFailureAction,
  reasonsWithdrawalRequestAction,
  reasonsWithdrawalSuccessAction,
} from './reasons-withdrawal.actions';

@Injectable()
export class ReasonsWithdrawalEffects {
  reasonsWithdrawalRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reasonsWithdrawalRequestAction),
      mergeMap(() =>
        this.walletService.reasonsWithdrawal().pipe(
          map(response => reasonsWithdrawalSuccessAction({ response })),
          catchError(error => {
            console.log('error desde reasonsWithdrawalEffects: ', error);
            return of(reasonsWithdrawalFailureAction({ error }));
          })
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly walletService: WalletService
  ) {}
}
