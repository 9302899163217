import { createReducer, on, State } from '@ngrx/store';
import {
  departmentRequestAction,
  departmentSuccessAction,
} from './ubigeo.actions';
import { UbigeoState } from './ubigeo.state';

export const initialUbigeoState: UbigeoState = {
  loadingDepartment: false,
  loadedDepartment: false,
  responseDepartment: undefined,
  errorDepartment: undefined,
};

export const ubigeoReducer = createReducer(
  initialUbigeoState,
  on(
    departmentRequestAction,
    (state): UbigeoState => ({
      ...state,
      loadingDepartment: true,
    })
  ),
  on(
    departmentSuccessAction,
    (state, { response }): UbigeoState => ({
      ...state,
      loadingDepartment: false,
      loadedDepartment: true,
      responseDepartment: response,
      errorDepartment: undefined,
    })
  )
);
// export const userReducer = createReducer(
//   initialUserState,
//   // startRegister
//   on(
//     startRegisterLoadingAction,
//     (state): UserState => ({
//       ...state,
//       loading: true,
//     }),
//   ),
//   on(
//     startRegisterRequestAction,
//     (state): UserState => ({
//       ...state,
//       loading: false,
//       response: undefined,
//       token: undefined,
//       error: undefined,
//     }),
//   ),
//   on(
//     startRegisterSuccessAction,
//     (state, action): UserState => ({
//       ...state,
//       loading: true,
//       response: action.response,
//       token: action.response.data.token,
//       error: undefined,
//     }),
//   ),
//   on(
//     startRegisterFailureAction,
//     (state, action): UserState => ({
//       ...state,
//       loading: true,
//       response: undefined,
//       error: action.error,
//     }),
//   ),
//   on(
//     startRegisterCancelAction,
//     (state): UserState => ({
//       ...state,
//       loading: false,
//       response: undefined,
//       token: undefined,
//       responseValidated: undefined,
//       tokenValidated: undefined,
//       error: undefined,
//     }),
//   ),
//   // verifyCode
//   on(
//     verifyCodeRequestAction,
//     (state): UserState => ({
//       ...state,
//       loading: false,
//       responseValidated: undefined,
//       tokenValidated: undefined,
//       error: undefined,
//     }),
//   ),
//   on(
//     verifyCodeSuccessAction,
//     (state, action): UserState => ({
//       ...state,
//       loading: true,
//       response: undefined,
//       token: undefined,
//       responseValidated: action.response,
//       tokenValidated: action.response.data.token,
//       error: undefined,
//     }),
//   ),
//   on(
//     verifyCodeFailureAction,
//     (state, action): UserState => ({
//       ...state,
//       loading: true,
//       responseValidated: undefined,
//       error: action.error,
//     }),
//   ),
//   // register
//   on(
//     registerRequestAction,
//     (state): UserState => ({
//       ...state,
//       loading: false,
//       // responseValidated: undefined,
//       // tokenValidated: undefined,
//       error: undefined,
//     }),
//   ),
//   on(
//     registerSuccessAction,
//     (state, action): UserState => ({
//       ...state,
//       loading: true,
//       response: undefined,
//       token: undefined,
//       responseValidated: undefined,
//       tokenValidated: undefined,
//       error: undefined,
//     }),
//   ),
//   on(
//     registerFailureAction,
//     (state, action): UserState => ({
//       ...state,
//       loading: true,
//       error: action.error,
//     }),
//   ),
// );
