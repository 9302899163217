import { createReducer, on } from '@ngrx/store';
import {
  walletTransactionRequestAction,
  walletTransactionShowModalAction,
  walletTransactionSuccessAction,
  walletTransactionHideModalAction,
  walletTransactionFailureAction,
} from './wallet-transactions.actions';
import { WalletTransactionState } from './';

export const initialWalletTransactionState: WalletTransactionState = {
  loading: false,
  loaded: false,
  response: undefined,
  error: undefined,
  openModal: false,
};

export const walletTransactionReducer = createReducer(
  initialWalletTransactionState,
  on(
    walletTransactionRequestAction,
    (state): WalletTransactionState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    walletTransactionSuccessAction,
    (state, { response }): WalletTransactionState => ({
      ...state,
      loading: false,
      loaded: true,
      response: response,
      error: undefined,
    })
  ),
  on(
    walletTransactionFailureAction,
    (state, action): WalletTransactionState => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
  on(
    walletTransactionShowModalAction,
    (state): WalletTransactionState => ({
      ...state,
      openModal: true,
    })
  ),
  on(
    walletTransactionHideModalAction,
    (state): WalletTransactionState => ({
      ...state,
      openModal: false,
    })
  )
);
