import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { BankService } from 'src/app/core/services';
import {
  BankFailureAction,
  BankRequestAction,
  BankSuccessAction,
} from './bank.actions';

@Injectable()
export class BankEffects {
  reasonsWithdrawalRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BankRequestAction),
      mergeMap(() =>
        this.bankService.banks().pipe(
          map(response => BankSuccessAction({ response })),
          catchError(error => {
            console.log('error desde bankEffects: ', error);
            return of(BankFailureAction({ error }));
          })
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly bankService: BankService
  ) {}
}
