import { createAction, props } from '@ngrx/store';
import {
  Auth,
  ResetPasswordRequest,
  ResponseService,
  Token,
} from 'src/app/core/interfaces';

export const loginLoading = createAction('[Auth] Login Load');

export const loginRequestAction = createAction(
  '[Auth] Login Request',
  props<{ email: string; password: string }>()
);

export const loginSuccessAction = createAction(
  '[Auth] Login Success',
  props<{ loginSuccessResponse: ResponseService<Auth> }>()
);

export const loginFailureAction = createAction(
  '[Auth] Login Failure',
  props<{ error: string | string[] }>()
);

export const logoutAction = createAction('[Auth] Logout');

// forgotPassword
export const forgotPasswordRequestAction = createAction(
  '[Auth] Forgot Password Request',
  props<{ email: string }>()
);

export const forgotPasswordSuccessAction = createAction(
  '[Auth] Forgot Password Success',
  props<{ response: ResponseService<Token> }>()
);

export const forgotPasswordFailureAction = createAction(
  '[Auth] Forgot Password Failure',
  props<{ error: string | string[] }>()
);

export const forgotPasswordCancelAction = createAction(
  '[Auth] Forgot Password Cancel'
);

// resetPassword
export const resetPasswordRequestAction = createAction(
  '[Auth] Reset Password Request',
  props<{ params: ResetPasswordRequest }>()
);

export const resetPasswordSuccessAction = createAction(
  '[Auth] Reset Password Success',
  props<{ response: ResponseService<boolean> }>()
);

export const resetPasswordFailureAction = createAction(
  '[Auth] Reset Password Failure',
  props<{ error: string | string[] }>()
);

export const resetPasswordCancelAction = createAction(
  '[Auth] Reset Password Cancel'
);
