import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { from, Observable, switchMap } from 'rxjs';
import { AuthTokenService } from '../services/auth-token.service';
import { GeneralService } from '../services/general.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authTokenService: AuthTokenService,
    private readonly generalService: GeneralService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const resp = from(this.authTokenService.init()).pipe(
      switchMap(async () => {
        const token = this.authTokenService.getTokenValue();
        if (token) {
          const isTokenExpired = this.authTokenService.isTokenExpired();
          if (isTokenExpired) {
            this.generalService.showAlert(
              `Su sesión ha expirado. Por favor, vuelva a iniciar sesión.`,
              null,
              `Aviso`,
              `Aceptar`
            );
            await this.authTokenService.clearTokens();
            await this.router.navigateByUrl('/login');
            return false;
          }
          return true;
        }
        await this.router.navigateByUrl('/login');
        return false;
      })
    );
    return resp;
  }
}
