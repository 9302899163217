// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  tokenWord: 'Bearer',
  // host: 'http://127.0.0.1:8100',
  // domain: '127.0.0.1:8100',
  // apiAuth: 'http://127.0.0.1:3000/auth',
  // apiServer: 'http://127.0.0.1:3000',
  // socketServer: 'http://127.0.0.1:3000',
  // socketServerPath: '/socket.io/',
  host: 'https://service.inversiones.io',
  domain: 'service.inversiones.io',
  apiAuth: 'https://service.inversiones.io/api/auth',
  apiServer: 'https://service.inversiones.io/api',
  socketServer: 'https://service.inversiones.io',
  socketServerPath: '/api/socket.io/',
  codSitio: 1,
  token: 'invioToken',
  tokenStartRegister: 'invioStartRegister',
  tokenUserValidated: 'invioUserTokenValidated',
  tokenResetPassword: 'invioResetPassword',
  maxOfferAmount: 999999999,
  maxOfferInstitucional: 0.99,
  maxOfferNoInstitucional: 0.2,
  urlProfileLogo: 'https://dev.inversiones.io/uploads/profile/logo/',
  urlProjectIllustration:
    'https://dev.inversiones.io/uploads/project/illustration/',
  urlImage: 'https://app.inversiones.io/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
