import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  Amounts,
  Currency,
  Pagination,
  PaymentTransaction,
  ReasonWithdrawal,
  Token,
  WalletInfo,
  WalletTransaction,
  WalletTransactionFilterDto,
  WithdrawMoneyDto,
} from '../interfaces';
import { SelectOption } from '../interfaces/select-option';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  #path = `${environment.apiServer}/wallet`;
  #pathTransactions = `${environment.apiServer}/wallet-transactions`;

  constructor(private readonly http: HttpClient) {}

  addMoney(
    currency: Currency,
    amount: number,
    fileName: string,
    fileBlob: Blob
  ) {
    const body = new FormData();
    body.append('currencyId', String(currency.id));
    body.append('amount', String(amount));
    body.append('file', fileBlob, fileName);
    return this.http.post<PaymentTransaction>(`${this.#path}/add-money`, body);
  }

  requestCode() {
    return this.http.post<Token>(`${this.#path}/request-code`, {});
  }

  reasonsWithdrawal() {
    return this.http.get<ReasonWithdrawal[]>(
      `${this.#path}/reasons-withdrawal`
    );
  }

  withdrawMoney(body: WithdrawMoneyDto) {
    return this.http.post<boolean>(`${this.#path}/with-draw-money`, body);
  }

  transactions(dto: WalletTransactionFilterDto) {
    return this.http.post<Pagination<WalletTransaction>>(
      `${this.#pathTransactions}`,
      dto
    );
  }

  amounts() {
    return this.http.get<Amounts>(`${this.#path}/amounts`);
  }

  getInfo() {
    return this.http.get<WalletInfo>(`${this.#path}/info`);
  }

  getOperationsOfTransactions() {
    return ['DEPOSITO', 'RETIRO'];
  }

  getStatusesOfTransactions() {
    return ['PENDIENTE', 'VALIDADO', 'RECHAZADO'];
  }

  getOperationsTabs(): SelectOption<string>[] {
    return [
      {
        label: 'Añadir Dinero',
        value: 'add',
      },
      {
        label: 'Retirar Dinero',
        value: 'withdraw',
      },
      {
        label: 'Transacciones',
        value: 'transactions',
      },
    ];
  }
}
