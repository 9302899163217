import { BankAccount } from 'src/app/core/interfaces';

export interface BankAccountState {
  loadingPen: boolean;
  loadedPen: boolean;
  responsePen: ReadonlyArray<BankAccount>;
  errorPen: string | string[];

  loadingUsd: boolean;
  loadedUsd: boolean;
  responseUsd: ReadonlyArray<BankAccount>;
  errorUsd: string | string[];
}
