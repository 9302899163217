import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { BankAccountService } from 'src/app/core/services';
import * as BankAccountActions from './';

@Injectable()
export class BankAccountEffects {
  // PEN
  bankAccountPenRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BankAccountActions.bankAccountPenRequestAction),
      mergeMap(() =>
        this.bankAccountService.getAccountsOfPen().pipe(
          map(response =>
            BankAccountActions.bankAccountPenSuccessAction({
              response,
            })
          ),
          catchError(error => {
            return of(
              BankAccountActions.bankAccountPenFailureAction({ error })
            );
          })
        )
      )
    );
  });

  // USD
  bankAccountUsdRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BankAccountActions.bankAccountUsdRequestAction),
      mergeMap(() =>
        this.bankAccountService.getAccountsOfUsd().pipe(
          map(response =>
            BankAccountActions.bankAccountUsdSuccessAction({
              response,
            })
          ),
          catchError(error => {
            return of(
              BankAccountActions.bankAccountUsdFailureAction({ error })
            );
          })
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly bankAccountService: BankAccountService
  ) {}
}
