import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Currency } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  #path = `${environment.apiServer}/currency`;

  constructor(private readonly http: HttpClient) {}

  currencies() {
    return this.http.get<Currency[]>(`${this.#path}`);
  }
}
