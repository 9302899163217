import { createAction, props } from '@ngrx/store';
import { ReasonWithdrawal } from 'src/app/core/interfaces';

export const reasonsWithdrawalRequestAction = createAction(
  '[Reasons Withdrawal] Wallet Transaction Request'
);

export const reasonsWithdrawalSuccessAction = createAction(
  '[Reasons Withdrawal] Wallet Transaction Success',
  props<{ response: ReasonWithdrawal[] }>()
);

export const reasonsWithdrawalFailureAction = createAction(
  '[Reasons Withdrawal] Wallet Transaction Failure',
  props<{ error: string | string[] }>()
);
