import { createReducer, on } from '@ngrx/store';
import {
  forgotPasswordCancelAction,
  forgotPasswordFailureAction,
  forgotPasswordRequestAction,
  forgotPasswordSuccessAction,
  loginFailureAction,
  loginLoading,
  loginRequestAction,
  loginSuccessAction,
  logoutAction,
  resetPasswordCancelAction,
  resetPasswordFailureAction,
  resetPasswordRequestAction,
  resetPasswordSuccessAction,
} from './auth.actions';
import { AuthState } from './auth.state';

export const initialAuthState: AuthState = {
  loading: false,
  authResponse: undefined,
  error: undefined,
  responseForgotPassword: undefined,
  tokenForgotPassword: undefined,
  responseResetPassword: undefined,
};

export const authReducer = createReducer(
  initialAuthState,
  on(
    loginLoading,
    (state): AuthState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    loginRequestAction,
    (state): AuthState => ({
      ...state,
      error: undefined,
    })
  ),
  on(
    loginSuccessAction,
    (state, action): AuthState => ({
      ...state,
      loading: true,
      authResponse: action.loginSuccessResponse,
      error: undefined,
    })
  ),
  on(
    loginFailureAction,
    (state, action): AuthState => ({
      ...state,
      loading: true,
      authResponse: undefined,
      error: action.error,
    })
  ),
  on(
    logoutAction,
    (state): AuthState => ({
      ...state,
      loading: false,
      authResponse: undefined,
      error: undefined,
    })
  ),
  // forgotPassword
  on(
    forgotPasswordRequestAction,
    (state): AuthState => ({
      ...state,
      loading: false,
      responseForgotPassword: undefined,
      tokenForgotPassword: undefined,
      responseResetPassword: undefined,
      error: undefined,
    })
  ),
  on(
    forgotPasswordSuccessAction,
    (state, action): AuthState => ({
      ...state,
      loading: true,
      responseForgotPassword: action.response,
      tokenForgotPassword: action.response.data.token,
      error: undefined,
    })
  ),
  on(
    forgotPasswordFailureAction,
    (state, action): AuthState => ({
      ...state,
      loading: true,
      responseForgotPassword: undefined,
      tokenForgotPassword: undefined,
      error: action.error,
    })
  ),
  on(
    forgotPasswordCancelAction,
    (state): AuthState => ({
      ...state,
      loading: false,
      responseForgotPassword: undefined,
      tokenForgotPassword: undefined,
      error: undefined,
    })
  ),
  // resetPassword
  on(
    resetPasswordRequestAction,
    (state): AuthState => ({
      ...state,
      loading: false,
      responseResetPassword: undefined,
      error: undefined,
    })
  ),
  on(
    resetPasswordSuccessAction,
    (state, action): AuthState => ({
      ...state,
      loading: true,
      responseResetPassword: action.response,
      error: undefined,
    })
  ),
  on(
    resetPasswordFailureAction,
    (state, action): AuthState => ({
      ...state,
      loading: true,
      responseResetPassword: undefined,
      error: action.error,
    })
  ),
  on(
    resetPasswordCancelAction,
    (state): AuthState => ({
      ...state,
      loading: false,
      responseResetPassword: undefined,
      error: undefined,
    })
  )
);
