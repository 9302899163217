import { createAction, props } from '@ngrx/store';
import { Currency } from 'src/app/core/interfaces';

export const currencyRequestAction = createAction(
  '[Currency] Currency Request'
);

export const currencySuccessAction = createAction(
  '[Currency] Currency Success',
  props<{ response: Currency[] }>()
);

export const currencyFailureAction = createAction(
  '[Currency] Currency Failure',
  props<{ error: string | string[] }>()
);
