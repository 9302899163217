import { formatNumber } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BoardOperationStatusEnum } from '../enums';
import {
  AccountStatus,
  AccountStatusDto,
  BoardOperation,
  BoardOperationFilterDto,
  BoardOperationSchedule,
  keyFigure,
  keyFigureResponse,
  OperationFigure,
  OperationStatus,
  Pagination,
} from '../interfaces';
import { SelectOption } from '../interfaces/select-option';

@Injectable({
  providedIn: 'root',
})
export class BoardService {
  #pathKeyFigures = `${environment.apiServer}/board-key-figures`;
  #pathAccountStatus = `${environment.apiServer}/board-account-status`;
  #pathOperations = `${environment.apiServer}/board-my-operations`;

  #status = BoardOperationStatusEnum;

  constructor(private readonly http: HttpClient) {}

  getOneKeyFigures(currencyId: number) {
    return this.http
      .get<keyFigureResponse>(`${this.#pathKeyFigures}/${currencyId}`)
      .pipe(
        map(res => {
          const symbol = res.shortName;
          const data: keyFigure[] = [
            {
              image: '/assets/images/ico-modern-building.png',
              label: 'Capital total en la plataforma',
              value: `${symbol} ${this.formatNumber(res.capitalTotal)}`,
            },
            {
              image: '/assets/images/ico-saldo-disponible.svg',
              label: 'Disponible',
              value: `${symbol} ${this.formatNumber(res.disponible)}`,
            },
            {
              image: '/assets/images/ico-capital-recuperar.png',
              label: 'Total Capital por recuperar',
              value: `${symbol} ${this.formatNumber(res.capitalPendiente)}`,
            },
            {
              image: '/assets/images/ico-interes-cobrado.png',
              label: 'Intereses cobrados',
              value: `${symbol} ${this.formatNumber(res.interesCobrado)}`,
            },
            {
              image: '/assets/images/ico-interes-por-cobrar.png',
              label: 'Intereses por cobrar',
              value: `${symbol} ${this.formatNumber(res.interesesPendiente)}`,
            },
            {
              image: '/assets/images/ico-capital-colecta.png',
              label: 'Capital comprometido en colecta',
              value: `${symbol} ${this.formatNumber(res.colectaCurso)}`,
            },
            {
              image: '/assets/images/ico-mora.png',
              label: '% Op. con retraso, mora y Refinanciadas',
              value: `${res.porcRetrazo} %`,
            },
          ];
          return data;
        })
      );
  }

  formatNumber(val: number, digitsInfo = '1.2-2') {
    return formatNumber(val, 'es-PE', digitsInfo);
  }

  getAccountStatus(dto: AccountStatusDto) {
    return this.http.post<Pagination<AccountStatus>>(
      `${this.#pathAccountStatus}`,
      dto
    );
  }

  getAccountStatusOperations() {
    return this.http.get<SelectOption[]>(
      `${this.#pathAccountStatus}/operaciones`
    );
  }

  getOperations(dto: BoardOperationFilterDto) {
    return this.http.post<Pagination<BoardOperation>>(
      `${this.#pathOperations}`,
      dto
    );
  }

  getOperation(offerId: number) {
    return this.http.get<BoardOperation>(`${this.#pathOperations}/${offerId}`);
  }

  getOperationStatuses(): OperationStatus[] {
    return [
      {
        shortLabel: 'Op. Vigentes',
        label: 'Operaciones Vigentes',
        labelOpertation: 'Cifras de Operaciones Vigentes',
        value: this.#status.VIGENTE,
        color: 'light-blue',
      },
      {
        shortLabel: 'Op. con Retraso',
        label: 'Operaciones con Retraso',
        labelOpertation: 'Cifras de Operaciones con Retraso',
        value: this.#status.RETRASO,
        color: 'orange',
      },
      {
        shortLabel: 'Op. con Mora',
        label: 'Operaciones con Mora',
        labelOpertation: 'Cifras de Operaciones con Mora',
        value: this.#status.MORA,
        color: 'red',
      },
      {
        shortLabel: 'Refinanciamiento',
        label: 'Refinanciamiento',
        labelOpertation: 'Cifras de Operaciones Refinanciadas',
        value: this.#status.REFINANCIADA,
        color: 'dark-blue',
      },
      {
        shortLabel: 'Op. Finalizadas',
        label: 'Operaciones Finalizadas',
        labelOpertation: 'Cifras de Operaciones Finalizadas',
        value: this.#status.FINALIZADO,
        color: 'grey',
      },
    ];
  }

  getOperationFigure(estado: BoardOperationStatusEnum) {
    return this.http.post<OperationFigure>(
      `${this.#pathOperations}/indicators`,
      { estado }
    );
  }

  getOperationListCorporateName(estado: BoardOperationStatusEnum) {
    return this.http.post<string[]>(
      `${this.#pathOperations}/list/corporate-name`,
      { estado }
    );
  }

  getOperationSchedule(offerId: number) {
    return this.http.get<BoardOperationSchedule[]>(
      `${this.#pathOperations}/schedule/${offerId}`
    );
  }
}
