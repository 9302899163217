import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private _activeRequest = 0;
  private loading: Promise<HTMLIonLoadingElement>;

  constructor(
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const action = request.headers.get('frontend-action') ?? null;
    // console.log('ErrorInterceptor.frontend', action ?? 'Sin acción');

    if (this._activeRequest === 0 && action !== 'silence') {
      this.loading = this.loadingCtrl.create({
        spinner: 'circular',
        message: 'Procesando solicitud...',
        translucent: true,
      });
      this.loading.then(load => {
        load.present();
      });
    }
    if (action !== 'silence') this._activeRequest++;
    // const loading = this.loadingCtrl.create({
    //   spinner: 'circular',
    //   message: 'Procesando solicitud...',
    //   translucent: true,
    // });

    // loading.then(load => {
    //   if (action !== 'silence') load.present();
    // });

    return next.handle(request).pipe(
      // catchError((err) => {
      //   if (err instanceof HttpErrorResponse) {
      //     // switch ((<HttpErrorResponse>err).status) {
      //     //   case 401:
      //     //   default:
      //     // }
      //     return throwError(err);
      //   } else {
      //     return throwError(err);
      //   }
      // }),
      // retryWhen((err) => {
      //   let retries = 1;
      //   return err.pipe(
      //     delay(1000),
      //     // take(3),
      //     tap(() => {
      //       this.showRetryToast(retries);
      //     }),
      //     map((error) => {
      //       if (retries++ === 3) {
      //         throw error;
      //       }
      //       return error;
      //     }),
      //   );
      // }),
      catchError(err => {
        console.log('ErrorInterceptor', [
          err.error.statusCode,
          err.status,
          err.error.error ?? 'Sin dato',
          err.error.message,
          err.error.code,
          err.error.name,
        ]);
        let error: string | string[] = '';
        switch ((err as HttpErrorResponse).status) {
          case 0:
            error = `No se puedo conectar al servidor.`;
            break;
          case 500:
            if (err.error) {
              error = err.error.message;
            } else {
              error = err;
            }
            break;
          default:
            if (err.error) {
              error = err.error.message;
            } else if (err.message) {
              error = err.message;
            } else {
              error = err;
            }
        }
        //return EMPTY;
        if (!Array.isArray(error)) {
          error = [error];
        }
        const errorHtml =
          `<div class="ion-text-center"><ion-icon name="alert-circle" class="error"></ion-icon></div>
          <ul class="ion-no-margin error">` +
          error
            .map(item => `<li><ion-label>${item}</ion-label></li>`)
            .join('') +
          `</ul>`;
        if (action !== 'silence') this.presentFailedAlert(errorHtml);
        return throwError(() => {
          return { error, fullError: err?.error };
        });
      }),
      finalize(() => {
        this._stopLoader(action);
      })
    );
  }

  async showRetryToast(retryCount: number) {
    const toast = await this.toastCtrl.create({
      message: `Reintento: ${retryCount}/3`,
      duration: 1000,
    });
    toast.present();
  }

  async presentFailedAlert(message: string) {
    const alert = await this.alertCtrl.create({
      // header: `¡Ups! Lo siento.`,
      message,
      buttons: ['Aceptar'],
      cssClass: 'alert-custom',
    });

    await alert.present();
  }

  private _stopLoader(action: string) {
    if (action !== 'silence') this._activeRequest--;
    if (this._activeRequest === 0 && this.loading) {
      this.loading.then(load => load.dismiss());
    }
  }
}
