import { createReducer, on } from '@ngrx/store';
import { UserState } from './user.state';
import {
  collectionChannelsFailureAction,
  collectionChannelsRequestAction,
  collectionChannelsSuccessAction,
  politicalExposedFailureAction,
  politicalExposedRequestAction,
  politicalExposedSuccessAction,
  registerFailureAction,
  registerRequestAction,
  registerSuccessAction,
  startRegisterCancelAction,
  startRegisterFailureAction,
  startRegisterLoadingAction,
  startRegisterRequestAction,
  startRegisterSuccessAction,
  verifyCodeFailureAction,
  verifyCodeRequestAction,
  verifyCodeSuccessAction,
} from './user.actions';

export const initialUserState: UserState = {
  loading: false,
  response: undefined,
  token: undefined,
  responseValidated: undefined,
  tokenValidated: undefined,
  error: undefined,

  loadingPoliticalExposed: false,
  responsePoliticalExposed: undefined,
  errorPoliticalExposed: undefined,

  loadingCollectionChannels: false,
  responseCollectionChannels: undefined,
  errorCollectionChannels: undefined,
};

export const userReducer = createReducer(
  initialUserState,
  // startRegister
  on(
    startRegisterLoadingAction,
    (state): UserState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    startRegisterRequestAction,
    (state): UserState => ({
      ...state,
      loading: false,
      response: undefined,
      token: undefined,
      error: undefined,
    })
  ),
  on(
    startRegisterSuccessAction,
    (state, action): UserState => ({
      ...state,
      loading: true,
      response: action.response,
      token: action.response.data.token,
      error: undefined,
    })
  ),
  on(
    startRegisterFailureAction,
    (state, action): UserState => ({
      ...state,
      loading: true,
      response: undefined,
      error: action.error,
    })
  ),
  on(
    startRegisterCancelAction,
    (state): UserState => ({
      ...state,
      loading: false,
      response: undefined,
      token: undefined,
      responseValidated: undefined,
      tokenValidated: undefined,
      error: undefined,
    })
  ),
  // verifyCode
  on(
    verifyCodeRequestAction,
    (state): UserState => ({
      ...state,
      loading: false,
      responseValidated: undefined,
      tokenValidated: undefined,
      error: undefined,
    })
  ),
  on(
    verifyCodeSuccessAction,
    (state, action): UserState => ({
      ...state,
      loading: true,
      response: undefined,
      token: undefined,
      responseValidated: action.response,
      tokenValidated: action.response.data.token,
      error: undefined,
    })
  ),
  on(
    verifyCodeFailureAction,
    (state, action): UserState => ({
      ...state,
      loading: true,
      responseValidated: undefined,
      error: action.error,
    })
  ),
  // register
  on(
    registerRequestAction,
    (state): UserState => ({
      ...state,
      loading: false,
      // responseValidated: undefined,
      // tokenValidated: undefined,
      error: undefined,
    })
  ),
  on(
    registerSuccessAction,
    (state, action): UserState => ({
      ...state,
      loading: true,
      response: undefined,
      token: undefined,
      responseValidated: undefined,
      tokenValidated: undefined,
      error: undefined,
    })
  ),
  on(
    registerFailureAction,
    (state, action): UserState => ({
      ...state,
      loading: true,
      error: action.error,
    })
  ),
  // Cargo de Persona Politicamente expuesta
  on(
    politicalExposedRequestAction,
    (state): UserState => ({
      ...state,
      loadingPoliticalExposed: true,
      errorPoliticalExposed: undefined,
    })
  ),
  on(
    politicalExposedSuccessAction,
    (state, { response }): UserState => ({
      ...state,
      loadingPoliticalExposed: false,
      responsePoliticalExposed: response,
      errorPoliticalExposed: undefined,
    })
  ),
  on(
    politicalExposedFailureAction,
    (state, action): UserState => ({
      ...state,
      loadingPoliticalExposed: false,
      errorPoliticalExposed: action.error,
    })
  ),
  // Canales de captación
  on(
    collectionChannelsRequestAction,
    (state): UserState => ({
      ...state,
      loadingCollectionChannels: true,
      responseCollectionChannels: undefined,
    })
  ),
  on(
    collectionChannelsSuccessAction,
    (state, { response }): UserState => ({
      ...state,
      loadingCollectionChannels: false,
      responseCollectionChannels: response,
      errorCollectionChannels: undefined,
    })
  ),
  on(
    collectionChannelsFailureAction,
    (state, action): UserState => ({
      ...state,
      loadingPoliticalExposed: false,
      errorPoliticalExposed: action.error,
    })
  )
);
