import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { from, Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralService } from '../services/general.service';
import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root',
})
export class StartRegisterVerifyGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly generalService: GeneralService,
    private readonly tokenService: TokenService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const resp = from(this.tokenService.init()).pipe(
      switchMap(async () => {
        await this.tokenService.setTokenValue(environment.tokenUserValidated);
        const tokenValidated = this.tokenService.getTokenData();
        if (tokenValidated) {
          const isTokenExpired = this.tokenService.isTokenExpired();
          if (isTokenExpired) {
            this.showAlert();
            await this.tokenService.clearTokens();
            await this.goToRegisterPage();
            return false;
          }
          let url = '/';
          switch (tokenValidated.data.accountType as number) {
            case 1:
              url = '/register-investor';
              break;
            case 2:
              url = '/register-investor';
              console.log('Muy pronto...');
              break;
          }
          if (state.url !== url) {
            await this.router.navigateByUrl(url);
          }
          return true;
        }
        await this.tokenService.setTokenValue(environment.tokenStartRegister);
        const token = this.tokenService.getTokenData();
        if (token) {
          const isTokenExpired = this.tokenService.isTokenExpired();
          if (isTokenExpired) {
            this.showAlert();
            await this.tokenService.clearTokens();
            await this.goToRegisterPage();
            return false;
          }
          return true;
        }
        await this.goToRegisterPage();
        return false;
      })
    );
    return resp;
  }

  showAlert() {
    this.generalService.showAlert(
      `Su sesión ha expirado. Por favor, vuelva a ingresar sus datos.`,
      null,
      `Aviso`,
      `Aceptar`
    );
  }

  async goToRegisterPage() {
    await this.router.navigateByUrl('/register');
  }
}
