import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  Operation,
  OperationListFilterDto,
  Pagination,
  SimulateInvestment,
  SimulateInvestmentDto,
} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class OperationService {
  #path = `${environment.apiServer}/operation`;

  constructor(private readonly http: HttpClient) {}

  get headers() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return headers;
  }

  getOperation(id: number) {
    return this.http.get<Operation>(`${this.#path}/list/${id}`);
  }

  getOperations(dto: OperationListFilterDto) {
    return this.http.post<Pagination<Operation>>(`${this.#path}/list`, dto);
  }

  getSimulateInvestment(dto: SimulateInvestmentDto) {
    let headers = this.headers;
    headers = headers.set('frontend-action', 'silence');
    return this.http.post<SimulateInvestment>(
      `${this.#path}/simulate-investment`,
      dto,
      { headers }
    );
  }

  invest(projectId: number, amount: number, fileName: string, fileBlob: Blob) {
    const body = new FormData();
    body.append('projectId', String(projectId));
    body.append('amount', String(amount));
    body.append('file', fileBlob, fileName);
    return this.http.post<any>(`${this.#path}/invest`, body);
  }
}
