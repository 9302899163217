import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { WalletTransactionState } from './';

export const selectWalletTransactionFeature = (state: AppState) =>
  state.walletTransaction;

export const selectWalletTransactionSuccess = createSelector(
  selectWalletTransactionFeature,
  (walletTransactionState: WalletTransactionState) =>
    walletTransactionState.response
);

export const selectWalletTransactionFailure = createSelector(
  selectWalletTransactionFeature,
  (walletTransactionState: WalletTransactionState) =>
    walletTransactionState.error
);

export const selectWalletTransactionStateModal = createSelector(
  selectWalletTransactionFeature,
  (walletTransactionState: WalletTransactionState) =>
    walletTransactionState.openModal
);
